<template>
   <TheModalWindow @modalClose="closeSmsCode">
      <form class="form" @submit.prevent="verifyCode">
         <label>
            <p>{{ $t('Registration.ConfirmPhone.label') }}</p>
            <input :class="showError ? 'error' : ''" type="text" v-model="value" />
            <p class="error__message" v-if="showError">{{ $t('Registration.ConfirmPhone.error') }}</p>
         </label>
         <ContactsSendCodeAgain @click.native.prevent :identifyer="identifyer" />
         <div class="btns">
            <SecondBtn
               :type="'button'"
               @click.native="closeSmsCode"
               :additionalStyles="'width: 100%; justify-content: center'"
               >Cancel</SecondBtn
            >
            <SecondBtn
               :type="'submit'"
               :isDisabled="!isValueValid"
               :additionalStyles="'width: 100%; justify-content: center;'"
               >Send</SecondBtn
            >
         </div>
      </form>
   </TheModalWindow>
</template>

<script>
import TheModalWindow from '../TheModalWindow.vue';
import SecondBtn from '../UI/SecondBtn.vue';
import ContactsSendCodeAgain from '../ContactsConponents/ContactsSendCodeAgain';
import appUrl from '../../store/appUrl';
export default {
   components: {
      TheModalWindow,
      SecondBtn,
      ContactsSendCodeAgain,
   },

   props: {
      identifyer: {
         type: String,
         required: true,
      },
   },

   data() {
      return {
         value: '',
         showError: false,
      };
   },

   computed: {
      isValueValid() {
         return new RegExp(/^[0-9]{8}$/).test(this.value);
      },
   },

   watch: {
      value() {
         this.isValueValid ? (this.showError = false) : (this.showError = true);
      },
   },

   methods: {
      async verifyCode() {
         try {
            let data = await this.$http.post('auth/verify/phone', { hash: this.value });
            localStorage.user_token = data.data.token;
            window.location.replace(appUrl + `?token=${data.data.token}`);
         } catch (error) {
            alert('Wrong code, try again');
         }
      },

      closeModal() {
         this.$destroy();
         this.$el.parentNode.removeChild(this.$el);
      },

      closeSmsCode() {
         this.$emit('closeModal', true);
      },
   },
};
</script>

<style lang="sass" scoped>
.form
   padding: 40px 15px

   & button
      max-width: 240px
      width: 100%

   & label
      margin-bottom: 20px

.btns
   margin-top: 25px
   display: flex
   justify-content: space-between
   align-items: center
   width: 100%

   & button:first-child
      margin-right: 10px
   & button:last-child
      margin-left: 10px

label
   width: 100%
   display: block
   & p
      text-transform: uppercase
      font-size: 1.3rem
      letter-spacing: 0.02em

   & > *
      display: block

input
   width: 100%
   border: 1px solid #ccc
   border-radius: 4px
   padding: 1rem 1rem 1rem 3rem
   font-size: 1.2rem
   margin-top: 0.5rem
   padding: 15px

   &:disabled
      cursor: not-allowed
      border: 1px solid lighten(#ccc, 12%)

.error
   border: 1px solid red !important

.error__message
   color: red
   font-size: 1rem
</style>