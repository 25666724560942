import { render, staticRenderFns } from "./ContactsRegisterForm.vue?vue&type=template&id=70aaa186&scoped=true&"
import script from "./ContactsRegisterForm.vue?vue&type=script&lang=js&"
export * from "./ContactsRegisterForm.vue?vue&type=script&lang=js&"
import style0 from "./ContactsRegisterForm.vue?vue&type=style&index=0&id=70aaa186&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70aaa186",
  null
  
)

export default component.exports