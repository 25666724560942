<template>
   <div class="registration">
      <div class="registration__discount-big">
         <QuizAsideDiscount :showTotal="true" />
      </div>
      <div class="login-selector">
         <button
            :class="isEmailSelection ? ['login-selector__btn', 'active'] : 'login-selector__btn'"
            @click="isEmailSelection = true"
         >
            Email
         </button>
         <button
            :class="!isEmailSelection ? ['login-selector__btn', 'active'] : 'login-selector__btn'"
            @click="isEmailSelection = false"
         >
            Phone
         </button>
      </div>
      <form @submit.prevent="postSingUp" ref="registerForm" class="registration__form">
         <div class="registration__number">
            <InputEmail
               v-if="isEmailSelection"
               :label="$t('Auth.emailLabel')"
               @isValid="data => (isIdentifyerValid = data)"
               :userValue.sync="identifyer"
            />
            <InputPhone
               v-else
               :label="$t('Auth.phoneLabel')"
               @isValid="data => (isIdentifyerValid = data)"
               :userValue.sync="identifyer"
            />
         </div>

         <div class="registration__number">
            <InputPassword
               class="forms-items"
               :label="$t('Registration.Form.passwordLabel')"
               @userInput="data => (password = data)"
               @isValid="data => (isPasswordValid = data)"
               :needValidation="!isPasswordValid"
               :disabled="!isIdentifyerValid"
               :errorMessage="$t('Auth.passwordError')"
            />
         </div>

         <!--          <div class="registration__number">
            <InputPassword
               class="forms-items"
               :label="$t('Registration.Form.passwordConfirmLabel')"
               @userInput="data => (passwordConfirm = data)"
               @isValid="data => (isPasswordConfirmValid = data)"
               :needValidation="!isPasswordConfirmed"
               :disabled="!isPasswordValid"
               :errorMessage="$t('Registration.Form.passwordConfirmError')"
               :errorShow="!isPasswordConfirmed && isPasswordValid && passwordConfirm.length > 1"
            />
         </div> -->

         <ContactsMessangers @click.native="saveUserDataToLocalStorage" />

         <transition name="fade-op">
            <div class="registration__btn">
               <SecondBtn
                  type="'submit'"
                  :isDisabled="!isIdentifyerValid || !isPasswordValid"
                  :additionalStyles="'width: 100%; justify-content: center;'"
                  :additionalType="'submit'"
                  >{{ smsWasClosed ? 'Continue' : 'Send' }}</SecondBtn
               >
            </div>
         </transition>
      </form>
      <transition name="fade">
         <ContactsConfirmMobileCode @closeModal="closeModal" v-if="mobileConfirmOpen" :identifyer="identifyer" />
      </transition>
   </div>
</template>

<script>
import InputPassword from '../UI/InputPassword.vue';
import InputPhone from '../UI/InputPhone.vue';
import InputEmail from '../UI/InputEmail.vue';
import QuizAsideDiscount from '../quizComponents/QuizAsideDiscount.vue';
import SecondBtn from '../UI/SecondBtn.vue';
import ContactsMessangers from './ContactsMessangers.vue';
import ContactsConfirmMobileCode from './ContactsConfirmMobileCode.vue';
export default {
   components: {
      InputEmail,
      InputPhone,
      InputPassword,
      SecondBtn,
      ContactsMessangers,
      QuizAsideDiscount,
      ContactsConfirmMobileCode,
   },

   data() {
      return {
         isEmailSelection: true,
         identifyer: '',
         isIdentifyerValid: false,
         isPasswordValid: false,
         isPasswordConfirmValid: false,
         password: '',
         passwordConfirm: '',
         email: '',
         phone: '',
         error: '',
         mobileConfirmOpen: false,
         postValue: {},
         smsWasClosed: false,
      };
   },

   computed: {
      isPasswordConfirmed() {
         if (this.password === '') {
            return false;
         }
         return this.password === this.passwordConfirm;
      },
   },

   watch: {
      isEmailSelection(prev, newVal) {
         sessionStorage.isEmailSelection = this.isEmailSelection;
         if (prev !== newVal && this.identifyer) {
            this.resetFrom();
         }
      },
   },

   mounted() {
      if (sessionStorage.isEmailSelection) {
         this.isEmailSelection = JSON.parse(sessionStorage.isEmailSelection);
      }
   },

   methods: {
      saveUserDataToLocalStorage() {
         let user = {
            first_name: this.$store.getters['quiz/getName'] || sessionStorage.name,
            questions:
               this.$store.getters['quiz/getUserAnswers'].length > 0
                  ? this.$store.getters['quiz/getUserAnswers']
                  : JSON.parse(sessionStorage.userAnswers),
            discount: this.$store.getters['quiz/getDiscount'],
         };
         localStorage.user_info = JSON.stringify(user);
      },

      async postSingUp() {
         if (this.smsWasClosed) {
            this.mobileConfirmOpen = true;
            return;
         }
         const first_name = this.$store.getters['quiz/getName'] || sessionStorage.name;
         const answers =
            this.$store.getters['quiz/getUserAnswers'].length > 0
               ? this.$store.getters['quiz/getUserAnswers']
               : JSON.parse(sessionStorage.userAnswers);
         const discount = this.$store.getters['quiz/getDiscount'];

         let provider;

         let postValue = {
            first_name: first_name,
            questions: answers,
            discount: discount,
            password: this.password,
         };

         if (this.isEmailSelection) {
            this.email = this.identifyer;
            postValue.email = this.identifyer;
            provider = 'email';
         } else {
            this.phone = this.identifyer;
            postValue.phone = this.identifyer;
            provider = 'phone';
         }

         this.postValue = postValue;
         try {
            if (postValue.phone) this.mobileConfirmOpen = true;
            const response = await this.$http.post(`/auth/register/${provider}`, this.postValue, {
               withCredentials: true,
            });
            sessionStorage.removeItem(provider);

            if (response?.data?.type === 'resend' && provider === 'email') {
               alert('The user is registered but not verified. An email has been sent to you to verify your account');
            } else if (response?.data?.type === 'resend' && provider === 'phone') {
               alert(
                  'The user is registered but not verified. A code has been sent to your phone to verify your account.',
               );
            } else {
               alert(
                  `Successfully registered. Check your ${provider === 'email' ? 'email' : 'phone'} for verify! ${
                     provider === 'email' ? "Do check your spam folder if it doesn't arrive in your inbox." : ''
                  }`,
               );
            }
            if (provider === 'email') {
               this.resetFrom();
               this.$router.push({ name: 'main' });
            }
         } catch (error) {
            if (error?.data?.message === 'User existed') {
               alert('Your email/phone has been registred!');
            }
         }
      },

      closeModal(data) {
         this.mobileConfirmOpen = false;
         this.smsWasClosed = data;
      },

      resetFrom() {
         sessionStorage.phone = '';
         sessionStorage.email = '';
         this.$refs.registerForm.reset();
         this.email = '';
         this.phone = '';
         this.identifyer = '';
         this.password = '';
         this.passwordConfirm = '';
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
.fade-op-enter-active,
.fade-op-leave-active
   transition: all 0.2s
   max-height: 230px

.fade-op-enter,
.fade-op-leave-to
   opacity: 0
   max-height: 0px

.registration
   box-shadow: 2.5px 8.7px 15px 0 rgba(0,0,0,.11)
   border: solid 1.2px #e8e8e8
   border-top: none
   border-bottom: none

   height: 100%
   width: 100%
   display: flex
   flex-direction: column
   justify-content: center
   padding: 1rem 2rem

   &__number
      margin-bottom: 1rem

   &__email
      margin-bottom: 1rem

   &__discount-big
      margin-bottom: 1rem

   &__btn
      margin-top: 1rem

   &__form
      min-height: 384px

.login-selector
   display: flex
   align-items: center
   margin-bottom: 10px
   & >:first-child
      margin-right: 20px
   &__btn
      cursor: pointer
      border: 1px solid #ccc
      padding: 10px
      border-radius: 5px
      transition: all 0.2s
      &.active
         background: #ccc

@media (max-width:750px)
   .registration
      margin: 0 auto
      align-items: center
      border: none
      box-shadow: unset
      display: block
      padding: 0 1rem
      padding-bottom: 1rem

      &__form
         margin-top: 1rem

      &__messangers
         position: absolute
         height: 100%
         width: 100%
         background-color: #fff
         z-index: 10000
         top: 0
         left: 0

         & > *
            position: absolute
            top: 50%
            left: 0
            width: 100%
            transform: translateY(-50%)
            padding: 1rem

      &__discount-big
         display: none

@media (max-width: 360px) and (max-height: 590px) and (orientation: portrait)
   .registration__number_label, .registration__email_text
      font-size: 0.8rem
      text-transform: uppercase

   .use-messanger
      font-size: 0.8rem
</style>
