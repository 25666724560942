<template>
   <button @click="handleClick" type="button" class="messanger">
      <img ref="imgMessanger" :src="'/img/' + imgName" alt="" />
   </button>
</template>

<script>
export default {
   props: {
      imgName: {
         required: true,
         type: String,
      },
      isAuth: {
         type: Boolean,
      },
   },

   methods: {
      async handleClick() {
         let url = await this.fetchUrl();
         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrl() {
         let response = await this.$http.post('auth/facebook/url');
         return response.data.url;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.fade-enter-active, .fade-leave-active
   transition: opacity .8s
   opacity: 1

.fade-enter, .fade-leave-to
   position: absolute
   opacity: 0
   right: 10000%

.messanger
   border-radius: 4px
   border: solid 1px #ebebeb
   background-color: #f5f7f9
   display: inline-flex
   align-items: center
   justify-content: center
   padding: 0.5rem 1rem
   cursor: pointer

   &:hover
      box-shadow: -2px 2px 14px 0 #ebedef

   & img
      height: 32px

   & p
      font-size: 1rem
</style>