<template>
	<div class="discount">
		<div class="discount__box">
			<div class="discount__content">
				<p class="discount__text">{{ $t('Discount.yourDiscount') }}</p>
				<p class="discount__percents">
					<span class="discount__percents-number"
						>{{ discount }}
						<div ref="discount__percents-number_anim-num" class="discount__percents-number_anim-num">
							{{ prevStep > currentStep ? '-' : '+' }} {{ discountPerStep }} %
						</div></span
					>%
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		showTotal: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		discount() {
			return this.showTotal
				? this.$store.getters[`quiz/getDiscount`]
				: this.$store.getters[`quiz/getCurrentDiscount`];
		},

		discountPerStep() {
			return this.$store.getters['quiz/getDiscountPerStep'];
		},

		prevStep() {
			return this.$store.getters['quiz/getPrevStep'];
		},

		currentStep() {
			return this.$store.getters['quiz/getCurrentStep'];
		},
	},

	watch: {
		currentStep() {
			this.discountAnimation();
		},
	},

	methods: {
		discountAnimation() {
			this.prevStep > this.currentStep
				? this.$refs['discount__percents-number_anim-num'].classList.add('red')
				: this.$refs['discount__percents-number_anim-num'].classList.remove('red');
			this.$refs['discount__percents-number_anim-num'].classList.add('discount__percents-number_animation');
			this.$refs['discount__percents-number_anim-num'].addEventListener('animationend', this.removeAnimClass);
		},

		removeAnimClass() {
			this.$refs['discount__percents-number_anim-num'].classList.remove('discount__percents-number_animation');
			this.$refs['discount__percents-number_anim-num'].removeEventListener('animationend', this.removeAnimClass);
		},
	},
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables.sass'

@keyframes arrowMove
	0%
		transform: rotate(-90deg) translateX(100%)
	50%
		transform: rotate(-90deg) translateX(0%)
	100%
		transform: rotate(-90deg) translateX(100%)

@keyframes addDiscount
	0%
		opacity: 1
		transform: translateX(-41%) translateY(-20%)
	90%
		opacity: 1
		transform: translateX(-41%) translateY(-120%)
	100%
		opacity: 0
		transform: translateX(-41%) translateY(-120%)

.discount
	padding: 1rem
	background-color: $bg-discount
	border: 2px solid darken($bg-discount, 10%)
	height: 100%

	&__box
		display: flex
		flex-direction: column
		justify-content: center
		width: 100%
		height: 100%

	&__content
		color: black
		letter-spacing: 0.1em

		display: flex
		align-items: center
		justify-content: space-between

	&__percents
		position: relative

		&::after
			content: url('../../assets/img/doubleArrows.svg')
			position: absolute
			top: 0
			right: 0
			width: 10px
			height: 10px
			animation: arrowMove 1.5s infinite

		&-number_anim-num
			color: $color-quiz-primary !important
			position: absolute
			font-size: 0.6rem
			color: #fdcd32
			top: 0
			left: 50%
			opacity: 0
			width: 150%

			&.red
				color: red !important

		&-number_animation
			animation: addDiscount 1s

@media (min-width: 800px)
	p
		font-size: 1rem

@media (max-width: 400px)
	.discount
		padding: 0.6rem
		font-size: 0.8rem
</style>
