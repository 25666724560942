<template>
   <div class="contacts">
      <section class="contacts__info">
         <div class="info">
            <div class="info__box">
               <div class="info__bonuses">
                  <p class="info__bonuses_text">{{ $t('Registration.Register.yourBonuses') }}</p>
                  <div class="info__bonuses_box">
                     <!-- <div class="info__bonuses_bonus">
                        <QuizAsideBonus :additionalStyles="'width: 100%'" :src="'/img/bonusImage.jpg'">
                           10 первых рекомендаций по улучшению качества сна и жизни
                        </QuizAsideBonus>
                     </div> -->

                     <!-- 						<div class="registration__discount">
								<div class="discount__text">Ваша скидка:</div><div class="discount__discount">{{ $store.getters.getDiscount }} %</div>
							</div> -->
                  </div>
               </div>

               <!-- <div class="info__title">Для вас скидка {{ discount }}% на ваш первый заказ</div>
               <div class="info__text">
                  Мы свяжемся с вами для подтверждения заказа и проконсультируем вас по любым вопросам. А пока можете
                  посмотреть наше полное меню с ценами
               </div> -->
            </div>
         </div>
      </section>
      <section class="contacts__registration">
         <ContactsRegisterForm />
      </section>
   </div>
</template>

<script>
// import QuizAsideBonus from '../components/quizComponents/QuizAsideBonus.vue';
import ContactsRegisterForm from '../components/ContactsConponents/ContactsRegisterForm.vue';

export default {
   components: {
      //   QuizAsideBonus,
      ContactsRegisterForm,
   },

   computed: {
      discount() {
         return this.$store.getters['quiz/getDiscount'];
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../sass/_variables'

@keyframes fade
	0%
		opacity: 0
		transform: translateX(100%)
	50%
		opacity: 1
	100%
		transform: translateX(0%)

.contacts
	width: 100%
	height: 100%
	animation: fade 1s

	display: flex

	&__info
		width: 100%
		height: 100%

	&__registration
		height: 100%
		max-width: 450px
		width: 100%

@media (max-width:750px)
	.contacts
		display: block

		&__info
			padding-top: 1rem
			width: 100%
			text-align: center
			height: auto

		&__registration
			max-width: unset
			width: 100%
			height: fit-content

.info
	height: 100%
	display: flex
	flex-direction: column
	justify-content: center
	padding: 0 4rem

	&__box
		min-height: 525px

	&__title
		font-size: 1.6rem
		margin-bottom: 1rem
		font-weight: 500

	&__text
		font-size: 1.3rem

	&__bonuses
		margin-bottom: 1rem

	&__bonuses_text
		text-transform: uppercase
		font-size: 0.9rem
		letter-spacing: 0.03em
		margin-bottom: 0.5rem

	&__bonuses_bonus
		min-height: 16vh
		max-width: 414px
		width: 100%

@media (max-width: 950px)
	.info
		padding: 1rem
		padding-bottom: 0
		padding-top: 0

@media (max-width: 750px)
	.info__bonuses_bonus
		min-height: 64px

	.info__box
		min-height: unset

@media (max-width: 500px) and (max-height: 812px) and (orientation: portrait)
	.info__bonuses
		display: none

@media (max-width: 500px) and (max-height: 700px) and (orientation: portrait)
	.info__title
		font-size: 0.9rem

	.info__text
		font-size: 0.8rem

.registration__discount
	max-width: 120px
	width: 100%
	color: $font-color-white
	margin-bottom: 1rem
	display: flex
	align-self: flex-start

.discount__text
	font-size: 0.8rem
	background-color: $color-quiz-primary
	border: 1px solid $font-color-dark-green
	padding: 0.1rem 0.8rem 0.1rem 0.3rem
	border-radius: 3px 0px 0px 3px

.discount__discount
	font-size: 0.8rem
	background-color: $font-color-dark-green
	padding: 0.1rem 0.3rem
	font-weight: 500
	position: relative
	border-radius: 3px 0px 0px 3px
	margin-left: -10px
	line-height: 1.7em

	&::after
		content: ""
		position: absolute
		background-color: #3c5e46
		width: 17px
		height: 17px
		top: 50%
		z-index: -1
		transform: translateY(-50%) translateX(-17%) rotate(45deg)
		border-radius: 0px 3px 0px 0px

	&::before
		content: ''
		position: absolute
		background-color: #fff
		height: 3px
		width: 3px
		top: 50%
		right: -6px
		border-radius: 100%
		transform: translate(-50%, -50%)
</style>
